import styled from 'styled-components';
import {StyledDiv} from './StyledSx';

interface FlexProps {
  direction?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  gap?: number;
}

export const Flex = styled(StyledDiv)<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  grid-gap: ${(props) => props.gap || 0}px;
`;

// i18next-extract-mark-ns-start subscribe
import {Content} from 'components/Content';
import {graphql} from 'gatsby';

import React, {useState} from 'react';
import {Formik} from 'formik';
import {Helmet, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Button} from 'components/Button';
import styled from 'styled-components';
import {colors, links} from '../global.config';
import {Loader} from 'components/Loader';
import {StyledP} from 'components/StyledSx';
import * as yup from 'yup';
import {SubscribeForm, UserConfig} from 'components/SubscribeForm';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  label {
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  color: #f5222d;
`;
const SubscribePage: React.FC = () => {
  const {t, language} = useI18next();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const formData = {
    userId: '',
    email: '',
    firstName: '',
    lastName: '',
    language,
    MonthlyNewsletter: false,
    PeriodicReminder: false,
    Announcements: false,
    unsubscribe: false
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Please, enter a correct email'))
      .required(t('Please, enter an email')),
    firstName: yup.string().required(t('Please, enter your name'))
  });

  const onSubmit = (formValues: any) => {
    setSuccess('');
    const values = Object.fromEntries(
      Object.entries(formValues).filter(([_, v]) => v !== null && v !== '')
    );

    const topics = [];
    if (values.MonthlyNewsletter) {
      topics.push('MonthlyNewsletter');
    }
    if (values.NewBlogPost) {
      topics.push('NewBlogPost');
    }
    if (values.PeriodicReminder) {
      topics.push('PeriodicReminder');
    }
    if (values.Announcements) {
      topics.push('Announcements');
    }

    setLoading(true);
    if (!values.userId) delete values.userId;
    fetch(links.pinpointApi + `user/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...values, topics, language})
    })
      .then((result) => result.json())

      .then((result) => {
        if (result.statusCode >= 400) {
          setError(result.message);
          return;
        }
        setSuccess(t('Thanks for subscribing!'));
        setLoading(false);
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const metaTitle = t('Subscribe to our newsletter');

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle} | MONEI</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Content sx={{padding: '0 20px', maxWidth: '624px', margin: '60px auto 120px'}}>
        <Trans parent="h1">Subscribe to our newsletter</Trans>

        <Formik<UserConfig>
          initialValues={formData}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}>
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <SubscribeForm displayedTopics={['Announcements', 'MonthlyNewsletter']} />
              <Button type="submit" variant="light" style={{width: '100%'}}>
                {loading ? <Loader /> : <Trans>Subscribe</Trans>}
              </Button>
              {success && (
                <div>
                  <StyledP sx={{color: colors.green, bottom: '0', margin: '0'}}>{success}</StyledP>
                </div>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Form>
          )}
        </Formik>
      </Content>
    </>
  );
};

export default SubscribePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["subscribe", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

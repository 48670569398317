import {Field, useFormikContext} from 'formik';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Input} from './Field';
import {Flex} from './Flex';
import Bold from './Bold';
import React, {useEffect} from 'react';
import {getClientId} from 'utils/helpers';
import styled from 'styled-components';
import {TFunction} from 'i18next';

export type UserConfig = {
  userId?: string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  MonthlyNewsletter: boolean;
  PeriodicReminder: boolean;
  Announcements: boolean;
  unsubscribe: boolean;
};

const ErrorMessage = styled.div`
  color: #f5222d;
  position: absolute;
`;
export const availableTopics = (t: TFunction) => [
  {
    key: 'Announcements',
    label: t('New product and feature announcements'),
    description: t(
      'Stay in the loop when we launch new products and features, including more payment methods.'
    )
  },
  {
    key: 'MonthlyNewsletter',
    label: t('Monthly newsletter'),
    description: t(
      'You will receive a monthly newsletter with the latest blog articles, payment insights and trends, expert business tips, and MONEI news.'
    )
  },
  {
    key: 'PeriodicReminder',
    label: t('Periodic reminder'),
    description: t('You will periodically receive reminders about your application status.')
  }
];

const Topic = styled.label`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: baseline;
`;

export const SubscribeForm: React.FC<{disableEmail?: boolean; displayedTopics?: string[]}> = ({
  disableEmail,
  displayedTopics = ['Announcements', 'MonthlyNewsletter', 'PeriodicReminder']
}) => {
  const {setFieldValue, errors, touched} = useFormikContext<UserConfig>();
  const {t} = useI18next();

  useEffect(() => {
    getClientId().then((userId) => {
      setFieldValue('userId', userId);
    });
  }, []);

  const topics = availableTopics(t).filter((topic) => displayedTopics.includes(topic.key));

  return (
    <>
      <label htmlFor="email">
        <Trans parent="p" style={{margin: 0}}>
          Email
        </Trans>
        <Input
          type="text"
          name="email"
          disabled={disableEmail}
          className={errors.email && touched.email ? 'has-error' : ''}
          style={
            disableEmail ? {pointerEvents: 'none', opacity: '0.75', backgroundColor: '#f7f7f7'} : {}
          }
        />
        {touched.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      </label>

      <label htmlFor="firstName">
        <Trans parent="p" style={{margin: 0}}>
          First name
        </Trans>
        <Input
          type="text"
          className={errors.firstName && touched.firstName ? 'has-error' : ''}
          name="firstName"
        />
        {touched.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
      </label>

      <label htmlFor="lastName">
        <Trans parent="p" style={{margin: 0}}>
          Last name
        </Trans>
        <Input type="text" name="lastName" />
      </label>

      {topics.map((topic) => (
        <Topic>
          <Field type="checkbox" name={topic.key} style={{marginTop: '6px', marginRight: '6px'}} />
          <Flex direction="column">
            <Bold>{topic.label}</Bold>
            <span>{topic.description}</span>
          </Flex>
        </Topic>
      ))}
    </>
  );
};
